import { DocumentSignerContainer } from "../document-signer/DocumentSignerContainer";
import { Nps } from "../nps/Nps";
import { ImagesContainer } from "../images/Images.container";
import { NotificationContainer } from "../notification/Notification.container";
import { SampleAuthRequest } from "../sample_requests/SampleAuthRequest";
import { SampleRequest } from "../sample_requests/SampleRequest";
import { Profile } from "../settings/Profile";
import { Forms } from "../forms/Forms";
export var AppRoutes = {
    "/nps/:id": Nps,
    "/forms/:id": Forms,
    "/profile": Profile,
    "/sample-request": SampleRequest,
    "/sample-auth-request": SampleAuthRequest,
    "/images": ImagesContainer,
    "/notificatiion": NotificationContainer,
    "/document-signer": DocumentSignerContainer,
};
