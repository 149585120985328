var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import "survey-core/defaultV2.min.css";
import { setLicenseKey } from "survey-core";
import "./index.css";
import { RouterManager } from "./modules/router/RouterManager";
setLicenseKey("NTIwMDBjNWMtODk5My00YzE1LWExNWItYmEyY2E0OTFjZjdjOzE9MjAyNS0xMi0xMiwyPTIwMjUtMTItMTIsND0yMDI1LTEyLTEy");
export var App = function () {
    return (_jsx(Grid, __assign({ container: true, spacing: 1 }, { children: _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(RouterManager, {}) })) })));
};
