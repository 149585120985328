var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Button, Typography, Grid, Card, Snackbar, Alert, CircularProgress, } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
export var DocumentSigner = function (_a) {
    var onSubmit = _a.onSubmit, register = _a.register, errors = _a.errors, feedback = _a.feedback, onCloseFeedback = _a.onCloseFeedback, isAuthenticated = _a.isAuthenticated, onFileChange = _a.onFileChange, onDownload = _a.onDownload, onCheckStatus = _a.onCheckStatus, fileId = _a.fileId, fileUrl = _a.fileUrl, coordinates = _a.coordinates, onPdfClick = _a.onPdfClick;
    var _b = React.useState(false), isSelecting = _b[0], setIsSelecting = _b[1];
    var _c = React.useState(false), isSubmitting = _c[0], setIsSubmitting = _c[1];
    var _d = React.useState(false), isCheckingStatus = _d[0], setIsCheckingStatus = _d[1];
    var _e = React.useState(null), signatureStatus = _e[0], setSignatureStatus = _e[1];
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSubmitting(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, onSubmit()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleCheckStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        var status;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsCheckingStatus(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, onCheckStatus()];
                case 2:
                    status = _a.sent();
                    setSignatureStatus(status);
                    return [3 /*break*/, 4];
                case 3:
                    setIsCheckingStatus(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Box, { children: [_jsx(Card, __assign({ sx: { py: 2, px: 6 } }, { children: _jsx(Grid, __assign({ container: true }, { children: _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(Typography, __assign({ align: "center", variant: "h5", gutterBottom: true }, { children: "Assinatura de Documento" })), _jsx("hr", {}), isAuthenticated ? (_jsx("form", __assign({ onSubmit: function (e) {
                                    e.preventDefault();
                                    handleSubmit();
                                } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx("input", __assign({ type: "file", accept: ".pdf" }, register("file"), { onChange: onFileChange })), errors.file && (_jsx(Typography, __assign({ color: "error" }, { children: errors.file.message })))] })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Button, __assign({ type: "submit", variant: "contained", color: "primary", fullWidth: true, disabled: isSubmitting, startIcon: isSubmitting ? (_jsx(CircularProgress, { size: 24 })) : undefined }, { children: "Enviar para Assinatura" })) }))] })) }))) : (_jsx(Typography, __assign({ variant: "subtitle1", align: "center" }, { children: "Inicie a sess\u00E3o para assinar um documento." }))), fileUrl && (_jsxs(Box, __assign({ sx: {
                                    position: "relative",
                                    width: "100%",
                                    height: "600px",
                                    mt: 4,
                                } }, { children: [_jsx("iframe", { src: fileUrl, title: "Visualizador de PDF", style: {
                                            width: "100%",
                                            height: "100%",
                                            border: "1px solid #ccc",
                                        } }), isSelecting && (_jsx("div", { role: "button", tabIndex: 0, "aria-label": "Selecione o local para a assinatura", onClick: function (event) {
                                            var rect = event.currentTarget.getBoundingClientRect();
                                            var x = event.clientX - rect.left;
                                            var y = event.clientY - rect.top;
                                            onPdfClick(x, y);
                                            setIsSelecting(false);
                                        }, onKeyDown: function (event) {
                                            if (event.key === "Enter" || event.key === " ") {
                                                var rect = event.currentTarget.getBoundingClientRect();
                                                var x = rect.width / 2;
                                                var y = rect.height / 2;
                                                onPdfClick(x, y);
                                                setIsSelecting(false);
                                            }
                                        }, style: {
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            cursor: "crosshair",
                                            backgroundColor: "rgba(0, 0, 0, 0)",
                                        } })), coordinates && (_jsx(CheckCircleIcon, { sx: {
                                            position: "absolute",
                                            top: coordinates.y,
                                            left: coordinates.x,
                                            transform: "translate(-50%, -50%)",
                                            color: "green",
                                            fontSize: "32px",
                                            pointerEvents: "none",
                                        } }))] }))), fileUrl && (_jsxs(Grid, __assign({ container: true, spacing: 2, sx: { mt: 2 } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Button, __assign({ variant: "outlined", color: isSelecting ? "error" : "primary", fullWidth: true, onClick: function () { return setIsSelecting(!isSelecting); }, startIcon: _jsx(LocationOnIcon, {}) }, { children: isSelecting
                                                ? "Cancelar Seleção do Local"
                                                : "Selecionar Local para Assinatura" })) })), fileId && (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Button, __assign({ variant: "outlined", color: "primary", fullWidth: true, onClick: handleCheckStatus, disabled: isCheckingStatus, startIcon: isCheckingStatus ? (_jsx(CircularProgress, { size: 24 })) : undefined }, { children: "Verificar Status da Assinatura" })) }))), fileId && signatureStatus === "APPROVED" && (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Button, __assign({ variant: "contained", color: "secondary", fullWidth: true, onClick: onDownload }, { children: "Baixar Documento Assinado" })) })))] })))] })) })) })), feedback && (_jsx(Snackbar, __assign({ open: true, autoHideDuration: 4000, onClose: onCloseFeedback }, { children: _jsx(Alert, __assign({ onClose: onCloseFeedback, severity: feedback.type, sx: { width: "100%" } }, { children: feedback.message })) })))] }));
};
