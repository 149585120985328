var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getForm, getNPS, postResposta } from "../../apis/nps";
import { Model, Survey } from "survey-react-ui";
export var Nps = function () {
    var _a = useState(""), data = _a[0], setData = _a[1];
    var _b = useState(false), respondido = _b[0], setRespondido = _b[1];
    var id = useParams().id;
    console.log(id);
    useEffect(function () {
        getNPS(id).then(function (result) {
            if (result.respondido) {
                setRespondido(true);
            }
            else {
                console.log(result);
                getForm(result.id_form).then(function (resultForm) {
                    try {
                        console.log(resultForm.json_data);
                        var parsedData = resultForm.json_data;
                        var updatedData = __assign(__assign({}, parsedData), { completeText: "Enviar", completedHtml: "Agradecemos pela sua resposta!" });
                        setData(updatedData);
                    }
                    catch (e) {
                        console.error(e);
                    }
                });
            }
        });
    }, [id]);
    var survey = new Model(data);
    survey.emptySurveyText = "Aguarde...";
    survey.onComplete.add(function (sender, options) {
        console.log(sender.data, options);
        options.showSaveSuccess("Resposta enviada com sucesso");
        postResposta(id, sender.data).then(function (r) { return console.log(r); });
    });
    return (_jsxs(Grid, __assign({ container: true }, { children: [_jsx(Grid, __assign({ container: true }, { children: _jsx(Typography, __assign({ variant: "h5", gutterBottom: true }, { children: "Pesquisa de Satisfa\u00E7\u00E3o" })) })), respondido ? (_jsx(Grid, __assign({ container: true }, { children: _jsx(Grid, __assign({ item: true }, { children: _jsx("h1", { children: "Essa pesquisa j\u00E1 foi respondida" }) })) }))) : (_jsx(Grid, __assign({ container: true }, { children: _jsx(Survey, { model: survey }) })))] })));
};
